import React, { useRef, useState } from "react";
import styled from 'styled-components';
import { Input, PageHeader, Popconfirm, Table, Image, Form, InputNumber, Dropdown, Menu } from "antd";
import moment from "moment";

import useQuery from "../../hooks/useQuery";
import usePaginatedRecordList from "../../hooks/usePaginatedRecordList";
import ListingWizard from "../../components/business/ListingWizard";
import { localizePrice } from "../../utils";
import { CheckCircleTwoTone, CloseCircleTwoTone, HomeOutlined, CodepenOutlined, BuildOutlined, DownOutlined, DeploymentUnitOutlined } from "@ant-design/icons";
import { DeleteOutlined, EyeInvisibleOutlined, EyeOutlined, OrderedListOutlined, EditOutlined, FolderAddOutlined } from "@ant-design/icons";
import RecordSelect from "../../components/fields/RecordSelect";
import { LocationModel } from "../../types/models/geo";
import Popform from "../../components/Popform";
import useRecordFiles from "../../components/useRecordFiles";
import RecordChannels from "../../components/RecordChannels";
import { ChannelModel } from "../../types/models/channels";

const { Search } = Input;


const Container = styled.div`
  height: 100%;
`;

const getColumns = (deleteRecord: any, patchRecord: any, onEdit: any, onExpand: any) => {
  const allColsSettings = { ellipsis: true };
  return [
    {
      title: '#', dataIndex: 'sort_index',
      render: (t: string) => <div style={{margin: -8}} className="fs-1 fw-300 center-text">{t}</div>,
      key: 'sort_index', width: 40
    },
    {
      title: 'Cover', dataIndex: 'cover_image',
      render: (imgUrl: string) => (
        <div style={{ margin: -8, height: 66 }}>
          <Image
            src={imgUrl}
            width={66}
          />
        </div>
      ),
      key: 'cover_image', width: 66 },
    // { title: 'Slug', dataIndex: 'slug', sorter: true, key: 'slug', width: 150 },
    { title: 'Name', dataIndex: 'name', sorter: true, key: 'name' },
    { title: 'Price', dataIndex: 'price', render: (p: any) => localizePrice(p.amount, p.currency, 'es'), sorter: true, key: 'price' },
    { title: 'Location', dataIndex: ['location', 'name'], sorter: true, key: 'location' },
    { title: 'Status', dataIndex: 'status', sorter: true, key: 'status' },
    {
      title: 'Published', dataIndex: 'published',
      render: ((p: boolean) => p ? <CheckCircleTwoTone twoToneColor="limegreen" /> : <CloseCircleTwoTone twoToneColor="red" />),
      key: 'id', width: 90
    },
    { title: 'Type', dataIndex: 'type',
      render: (type: string) => {
        switch (type) {
          case "unit":
            return <div className="center"><HomeOutlined style={{ color: 'rgba(0,0,0,.36)' }} /> <small>Unit</small></div>;
          case "design":
            return <div className="center disp-flex"><CodepenOutlined style={{ color: 'rgba(0,0,0,.36)' }} /><small>Design</small></div>;
          case "development":
            return <div className="center disp-flex"><BuildOutlined style={{ color: 'rgba(0,0,0,.36)' }} /><small>Dev.</small></div>;
        }
      },
      key: 'type', width: 75 },
    // { title: 'Latitude', dataIndex: 'lat', sorter: true, key: 'lat', width: 110 },
    // { title: 'Longitude', dataIndex: 'lng', sorter: true, key: 'lng', width: 110 },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      sorter: true,
      render: (c: string) => moment(c).format('ll'),
      width: 110
    },
    {
      title: 'Actions', render: (r: any) => (
        <div className="row-actions">
          {/*
          <RecordGalleriesModal recordName={r.name} recordPath="listings" recordId={r.slug}>
            <a>Images</a>
          </RecordGalleriesModal>
          */}
          <Popform
            placement="left"
            title={`Change sort order of ${r.name}`}
            formContent={(
              <Form.Item
                initialValue={r.sort_index}
                label="Sort #"
                name="sort_index"
                rules={[{ required: true, message: 'required' }]}
              >
                <InputNumber />
              </Form.Item>
            )}
            onSubmit={(data: any) => {
              patchRecord(r.slug, data, 'slug');
            }}
          >
            <a><OrderedListOutlined /> Reorder</a>
          </Popform>
          <Popconfirm
            title={`${r.published ? 'Unpublish' : 'Publish'} this listing?`}
            onConfirm={() => patchRecord(r.slug, { published: !r.published }, 'slug')}
            okText="Yes"
          >
            <a>{r.published ? <EyeInvisibleOutlined /> : <EyeOutlined />} {r.published ? 'Unpublish' : 'Publish'}</a>
          </Popconfirm>
          <a onClick={() => onEdit(r)}><EditOutlined /> Edit</a>
          <Dropdown
            overlay={(
              <Menu>
                <Menu.Item>
                  <a onClick={() => onExpand(r.slug)}><FolderAddOutlined /> Files</a>
                </Menu.Item>
                <Menu.Item>
                  <RecordChannels recordPath="listings" recordId={r.slug} recordName={r.name} />
                </Menu.Item>
                <Menu.Item>
                  <Popconfirm
                    title={`Permanently delete the listing "${r.name}"?`}
                    onConfirm={() => deleteRecord(r.slug)}
                    okText="Yes"
                  >
                    <a><DeleteOutlined /> Delete</a>
                  </Popconfirm>
                </Menu.Item>
              </Menu>
            )}
          >
            <a>More <DownOutlined /></a>
          </Dropdown>
        </div>
      ),
      width: 300
    }
  ].map((e: any) => ({ ...e, ...allColsSettings }));
}


function Listings() {
  const { query, handleSearch } = useQuery();
  const {
    pagination,
    createRecord,
    deleteRecord,
    patchRecord,
    handleChange,
    isLoading,
    recordList,
    firstFetch,
    recordFields,
    fetchRecordLocalizedTexts,
    contentType
  } = usePaginatedRecordList<any>({
    query,
    recordPath: 'listings',
    extraQueryAppend: '&omit=images,files&expand=location',
    apiService: 'admin',
    pageSize: '25',
    initialAutoFetch: true,
    initialOptionsFetch: true
  });
  const elementRef: any = useRef(null);
  const [editRecord, setRecordEdit] = useState<any | null>(null);
  const { tableExpandableConfig, onExpand, rowKeysMapper } = useRecordFiles('listings', 'slug');

  const onEdit = (id: any) => {
    setRecordEdit(id);
  }

  return (
    <Container ref={elementRef}>
      <PageHeader
        title="Listings"
        subTitle={pagination.total?.toLocaleString('en-US') + ' records'}
        extra={[
          !!recordFields ? (
            <ListingWizard
              fetchRecordLocalizedTexts={fetchRecordLocalizedTexts}
              createRecord={createRecord}
              recordFields={recordFields}
              contentType={contentType}
            />
          ) : null
        ]}
        className="mb-md"
      >
        <Search
          placeholder="Search by name..."
          onSearch={handleSearch}
          defaultValue={query.get('search') || ''}
          allowClear
          style={{ width: 300 }}
        />
        <RecordSelect
          recordModel={LocationModel}
          filter
        />
        <RecordSelect
          recordModel={ChannelModel}
          valueKey="name"
          filter
        />
      </PageHeader>

      <Table
        columns={getColumns(deleteRecord, patchRecord, onEdit, onExpand)}
        dataSource={recordList.map(rowKeysMapper)}
        pagination={pagination}
        loading={isLoading}
        onChange={handleChange}
        scroll={{ y: elementRef.current?.clientHeight - 180 }}
        size="small"
        rowKey="slug"
        expandable={tableExpandableConfig}
      />
      {!!recordFields &&
      <ListingWizard
        fetchRecordLocalizedTexts={fetchRecordLocalizedTexts}
        recordFields={recordFields}
        createRecord={createRecord}
        patchRecord={patchRecord}
        record={editRecord}
        setRecord={setRecordEdit}
        onClose={firstFetch}
        contentType={contentType}
      />}
    </Container>
  )
}

export default Listings;
