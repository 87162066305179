import { Identifiable, RecordModel } from "../core";
import { components } from "../schema";
import { OwnerType } from "../coreModels";
import { LocationModel } from "./geo";
import { Form, Select } from 'antd';

export type IUnit = components['schemas']['Unit'];
export type IDesign = components['schemas']['Design'];
export type IAmenity = {
  id: number;
  name: string;
  code: string;
  sort_index: number;
  icon: string;
};

export type ICategory = {
  id: number;
  name: string;
}

export type ICategorizedAmenity = {
  id: number;
  category: ICategory;
  amenity: IAmenity;
  note: string;
}

export const DesignModel: RecordModel<IDesign> = {
  name: "Design",
  path: "housing-designs",
  selectQuery: "&fields=id,name",
  tableQuery: "",
  toString(r): string {
    return r.name ? r.name : r.toString();
  }
}

export const UnitModel: RecordModel<IUnit> = {
  name: "Unit",
  path: "housing-units",
  selectQuery: "&fields=id,name",
  // selectQuery: "&fields=id,name,condo.name,base_house_id&expand=condo",
  tableQuery: "",
  toString(r): string {
    return r.name;
  },
  hasImageGalleries: true,
  customTableMappings: {
    design: { omit: true },
    lat: { omit: true },
    lng: { omit: true },
    fractions_taken: { omit: true },
    unit_ownerships: { omit: true },
    unitopex_set: { omit: true },
    unitcapex_set: { omit: true },
    zip_code: { omit: true },
  },
  customCreateFields: [
    { name: 'price', component: null }, // we skip setting the price on create
    { name: 'currency_iso', component: null }, // we skip this as well
  ]
}

export interface IListing extends Identifiable {
  name: string;
  slug: string;
  lat: string;
  lng: string;
  type: 'unit' | 'design';
  published: boolean;
  sort_index: number;
  amenities: any[];
  images: string[];
}

export const ListingModel: RecordModel<IListing> = {
  name: "Listing",
  path: "listings",
  selectQuery: "&fields=id,name,slug",
  tableQuery: "&expand=location,unit&fields=*,location.name,location.id,unit.id,unit.name&omit=files,characteristics,design,images",
  toString: (record) => record.name,
  primaryKeyName: 'slug',
  customCreateFields: [
    {
      name: 'status',
      component: (
        <Form.Item name="status" label="Status" rules={[{ required: true }]}>
          <Select placeholder="Select the listing status">
            <Select.Option value="pre_sale">Pre Sale</Select.Option>
            <Select.Option value="new_arrival">New Arrival</Select.Option>
            <Select.Option value="available_now">Available Now</Select.Option>
            <Select.Option value="sold">Sold</Select.Option>
          </Select>
        </Form.Item>
      )
    }
  ],
  customTableMappings: {
    price: { path: ['price', 'amount'] },
    amenities: { omit: true },
    files: { omit: true },
    characteristics: { omit: true },
    design: { omit: true },
    images: { omit: true },
    lat: { omit: true },
    lng: { omit: true },
    address: { omit: true },
    cover_image: { omit: true },
    description: { omit: true },
    usage_modes: { omit: true },
    benefits: { omit: true },
  },
  tableFilters: [
    { selectModel: LocationModel },
  ]
}

export const AmenityModel: RecordModel<IAmenity> = {
  name: "Amenity",
  namePlural: "Amenities",
  path: "amenities",
  selectQuery: "",
  tableQuery: "",
  toString(r): string {
    return r.name || r.code;
  },
  toDescriptor: r => (
    <div className="disp-flex h-stack" style={{ gap: 2, flexWrap: 'wrap' }}>
      <img src={r.icon} width={25} style={{ marginRight: 4 }} /><br/>
      <small>{r.name || r.code}</small>
    </div>
  ),
  tableFilters: [
    { selectModel: ListingModel }
  ],
  useMultipartFormData: true,
  allowSearch: true,
  crossTableQuery: '&fields=id,code,icon',
  crossRecordViews: [
    { toModel: ListingModel, toExtraQuery: '&fields=id,name,amenities' }
  ],
}

export const OwnerModel: RecordModel<OwnerType> = {
  name: "Owner",
  path: "unit-owners",
  selectQuery: "&expand=owner,unit&fields=id,main_accounting_journal,unit.name,owner.full_name,owner.email&has_no_journals=false",
  tableQuery: "&expand=owner,unit",
  toString: (record: OwnerType) => `${record.owner.full_name} - ${record.unit.name}`
}

export const CategoryModel: RecordModel<ICategory> = {
  name: "Category",
  namePlural: "Categories",
  path: 'categories',
  selectQuery: "&expand=amenity,category",
  tableQuery: "&expand=amenity,category",
  toString: r => r.name,
}


export const CategorizedAmenityModel: RecordModel<ICategorizedAmenity> = {
  name: "Categorized Amenity",
  namePlural: "Categorized Amenities",
  path: 'categorized-amenities',
  selectQuery: "&expand=amenity,category",
  tableQuery: "&expand=amenity,category",
  toString: r => `${r.amenity.name} - ${r.category.name}`,
  toDescriptor: r => !!AmenityModel.toDescriptor ? AmenityModel.toDescriptor({ ...r.amenity, name: CategorizedAmenityModel.toString(r) }) : CategorizedAmenityModel.toString(r),
  crossTableQuery: '&expand=amenity,category',
  crossRecordViews: [
    { toModel: ListingModel, toExtraQuery: '&fields=id,name,categorized_amenities' }
  ],
}
