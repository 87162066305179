import styled from 'styled-components';
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Dropdown, Menu, PageHeader, Select, Space, Table, Tag, Tooltip } from "antd";
import {
  StarTwoTone,
  SmileTwoTone,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  LoginOutlined,
  DollarOutlined,
  PoweroffOutlined,
  DownOutlined, CheckOutlined
} from '@ant-design/icons'
import * as R from "ramda";
import moment from 'moment';

import { ancanaApi } from "../../api/ancanaApi";
import FormDrawer from "../../components/FormDrawer";
import HouseOwnerPicksEditor from "../../components/HouseOwnerPicksEditor";
import RecordComments from "../../components/RecordComments";
import RecordTable from "../../components/RecordTable";
import { IBooking, IBookingRound, SpecialStayModel } from "../../types/models/lodging";


const Container = styled.div`
  height: 100%;
`;

function rotateArrayLeft(arr: any[], n: number) {
  // Calculate the actual rotation index
  const rotationIndex = n % arr.length;

  // Slice the array into two parts based on the rotation index
  const leftPart = arr.slice(rotationIndex);
  const rightPart = arr.slice(0, rotationIndex);

  // Concatenate the two parts in the desired order
  return leftPart.concat(rightPart);
}

interface IAssignment {
  week: number, round: number, fraction: number, preferred: boolean,
  checkin: string, checkout: string,
}

function BookingRoundDetails() {
  const { round_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [bookingRound, setBookingRound] = useState<IBookingRound>();
  const [weekRanking, setWeekRanking] = useState<any[]>([]);
  const [bookings, setBookings] = useState<IBooking[]>([]);
  const [fractionsCount, setFractionsCount] = useState<number>(0);
  const [houseOwners, setHouseOwners] = useState<{ id: number; fraction_numbers: number[], owner: any }[]>([]);
  const [currentOwnerEdit, setCurrentOwnerEdit] = useState<any>();
  const [assignments, setAssignments] = useState<IAssignment[]>([]);
  const [variation, setVariation] = useState("0");
  const numberOfRounds = (weekRanking.length > 0 && fractionsCount > 0) ? (weekRanking.length/fractionsCount) : 1;
  const elementRef: any = useRef(null);

  const weekNumberToString = (week: number, format = 'll') => {
    if (!bookingRound) return null;
    const dateStart = moment().set('year', bookingRound.year).set('weeks', week).startOf('week').add(3, 'days');
    const dateEnd = moment().set('year', bookingRound.year).set('weeks', week).endOf('week').add(3, 'days');
    let startFormat = format;
    if (dateStart.year() === dateEnd.year()) {
      startFormat = 'MMM D, YYYY';
    }
    return (
      <p className="pre-line" style={{ margin: 0, padding: 0, lineHeight: 1.25 }}>
        {`${dateStart.format(startFormat)} to
        ${dateEnd.format(format)}`}
      </p>
    )
  }

  const handleClose = () => {
    setLoading(true);
    ancanaApi.get(`/booking-rounds/${round_id}/close/?variation=${variation}`).then(() => window.location.reload());
  }

  const handleOpen = () => {
    setLoading(true);
    ancanaApi.get(`/booking-rounds/${round_id}/open/`).then(() => window.location.reload());
  }

  useEffect(() => {
    setLoading(true);
    ancanaApi.get(`/booking-rounds/${round_id}/?expand=unit.unit_ownerships.owner,unit&fields=*,unit.id,unit.name,unit.unit_ownerships,unit.fractions`).then(res => {
      setBookingRound(res.data);
      setFractionsCount(res.data.unit.fractions);
      setHouseOwners(res.data.unit.unit_ownerships);
      setLoading(false);
    });
    /*
    ancanaApi.get(`/booking-rounds/${round_id}/week-ranking`).then(res => {
      setWeekRanking(res.data);
    });
     */
    ancanaApi.get(`/bookings/?page_size=55&round=${round_id}`).then(res => {
      setBookings(res.data.results);
    });
  }, []);

  useEffect(() => {
    ancanaApi.get(`/booking-rounds/${round_id}/assignments/?variation=${variation}`).then(res => {
      setAssignments(res.data.assignments);
      setWeekRanking(res.data.ranking);
    });
  }, [variation]);

  if (loading) return <div>Loading...</div>;

  if (!bookingRound || typeof bookingRound?.unit === 'number') return (
    <div>Booking round not found, or related unit not expanded</div>
  );

  return (
    <Container ref={elementRef}>
      <PageHeader
        onBack={() => window.history.back()}
        title={`Booking Round`}
        subTitle={`${bookingRound?.unit.name} - ${bookingRound?.year}`}
        className="mb-md"
        extra={[
          <RecordTable
            recordModel={SpecialStayModel}
            modalView
            recordsetFilters={(bookingRound.usage_date_start && bookingRound.usage_date_start) ? (
              {
                'unit': bookingRound?.unit.id,
                'date_after': bookingRound.usage_date_start,
                'date_before': bookingRound.usage_date_end
              }
            ) : (
              { 'unit': bookingRound?.unit.id, 'year': bookingRound?.year }
            )}
          />,
          <RecordComments
            recordPath="booking-rounds"
            recordId={Number(round_id)}
            recordName={`${bookingRound?.unit.name} - ${bookingRound?.year}`}
            buttonCta="General Comments"
          />,
          <FormDrawer
            drawerWidth={550}
            actionName="View Price Ranks"
            btnType="default"
            btnIcon={<DollarOutlined />}
            recordName=""
            handleRecordAction={null}
            customFooter={<div />}
          >
            <Table
              columns={[
                { title: 'Price Rank', dataIndex: 'rank', width: 55, render: (n) => `#${n.toLocaleString()}`, },
                {
                  title: 'Avg. Nightly Rate',
                  dataIndex: 'price_avg',
                  width: 125,
                  render: (n) => `$${n.toLocaleString()}`
                },
                {
                  title: 'Week of year',
                  dataIndex: 'week',
                  width: 85,
                  render: (week) => <Tooltip title={weekNumberToString(week)}>{week}</Tooltip>
                },
                {
                  title: 'Assigned',
                  dataIndex: 'week',
                  render: (week) => {
                    const assignment = assignments.find(a => a.week == week);
                    const fractionOwner = houseOwners.find(ho => ho.fraction_numbers.includes(assignment?.fraction || 0));
                    if (!assignment) {
                      return (
                        <div>
                          <CloseCircleTwoTone twoToneColor="red" />
                        </div>
                      )
                    }
                    return (
                      <div>
                        <CheckCircleTwoTone
                          twoToneColor="limegreen" /> {fractionOwner?.owner.first_name || `Empty Fraction #${assignment.fraction}`}
                      </div>
                    )
                  }
                },
              ]}
              dataSource={weekRanking}
              pagination={false}
            />
          </FormDrawer>,
          <Dropdown
            disabled={loading}
            overlay={() => (
              <Menu>
                {bookingRound.closed ? (
                  <Menu.Item onClick={handleOpen}>Open</Menu.Item>
                ) : (
                  <Menu.Item onClick={handleClose}>Close</Menu.Item>
                )}
              </Menu>
            )}
            key="1"
            trigger={["click"]}
          >
            <Button className="capitalize" type="primary" loading={loading}>
              <Space>{bookingRound.closed ? 'Closed' : 'Open'} <DownOutlined /></Space>
            </Button>
          </Dropdown>
        ]}
      >
        <Select defaultValue="0" onSelect={e => setVariation(e)}>
          <Select.Option value="0">Prioritize owners' preference</Select.Option>
          <Select.Option value="1">Prioritize price rank</Select.Option>
        </Select>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 16, alignItems: 'center' }}>
          <div className="fs-2-66 fw-500">Symbols:</div>
          <div>
            <DollarOutlined /> Price Rank
          </div>
          <div>
            <SmileTwoTone /> Preferred week
          </div>
          <div>
            <StarTwoTone twoToneColor="orange" /> Premium week
          </div>
        </div>
      </PageHeader>


      <Table
        bordered
        columns={[
          { title: '', dataIndex: 'name', width: 75, fixed: 'left' },
          ...rotateArrayLeft(R.range(1, fractionsCount + 1), bookingRound.start_index - 1).map(fn => {
            const ho = houseOwners.find(ho => ho.fraction_numbers.includes(fn));
            const totalValue = (
              assignments
                .filter(assigment => assigment.fraction === fn)
                .reduce((a, b) => {
                  // console.log('a,b', a, b.week, weekRanking.find(rank => rank.week === b.week)?.avg_price);
                  return a + (weekRanking.find(rank => rank.week === b.week)?.price_avg || 0);
                }, 0)
                .toFixed(2)
            );
            return ({
              title: (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Tooltip
                    title={(
                      <div>
                        <div>{ho?.owner.full_name}</div>
                        <div>Fraction #{fn}</div>
                        <div>Total Value: <span className="fw-400">${Number(totalValue).toLocaleString('en')}</span></div>
                      </div>
                    )}
                  >
                    <div style={{ letterSpacing: -0.5, fontWeight: ho?.id ? '500' : '400' }}>
                      {ho?.owner.first_name || 'Empty'} (#{fn})
                    </div>
                  </Tooltip>
                  <Button
                    type="link"
                    size="small"
                    className="fs-2 cursor-pointer mb-xs"
                    onClick={() => setCurrentOwnerEdit(ho)}
                  >
                    {ho?.id ? (<><LoginOutlined /> Preferred Weeks</>) : '-'}
                  </Button>
                </div>
              ),
              render: bookingRound.closed ? (
                (rr: any) => {
                  const booking = bookings.find(b => b.data?.assignment.fraction === fn && b.data?.assignment.round === rr.round);
                  return booking ? (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <div className="text-center fw-500">
                        {/* weekNumberToString(assignment?.week || 0) */}
                        {moment(booking.start_date).format('ll')}
                      </div>
                      <div className="fs-1-5" style={{
                        marginTop: 2,
                        display: 'flex',
                        gap: 12,
                        alignItems: 'center',
                        letterSpacing: -0.5
                      }}>
                        <span><DollarOutlined style={{ fontSize: 12 }} /> #{booking.data?.assignment?.rank}</span>
                        {booking.is_preferred && <SmileTwoTone style={{ fontSize: 14 }} />}
                      </div>
                    </div>
                  ) : '?';
                }
              ) : (
                (rr: any) => {
                  const assignment = assignments.find(a => a.fraction === fn && a.round === rr.round);
                  const weekRankPos = weekRanking.find(wr => wr.week === assignment?.week);
                  return (
                    <Tooltip
                      title={(
                        <ul style={{ padding: '0 0 0 12px', margin: 0 }}>
                          {assignment?.preferred && (<li>Owner's Preferred <SmileTwoTone /></li>)}
                          <li>#{weekRankPos?.rank} in Price Rank <DollarOutlined /></li>
                          <li>${weekRankPos?.price_avg.toLocaleString()} Avg per night</li>
                          <li>Week {assignment?.week} of the year</li>
                        </ul>
                      )}
                      style={{ textAlign: 'center' }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div className="text-center">
                          {/* weekNumberToString(assignment?.week || 0) */}
                          {moment(assignment?.checkin || undefined).format('ll')}
                        </div>
                        <div className="fs-1-5" style={{
                          marginTop: 2,
                          display: 'flex',
                          gap: 12,
                          alignItems: 'center',
                          letterSpacing: -0.5
                        }}>
                          <span><DollarOutlined style={{ fontSize: 12 }} /> #{weekRankPos?.rank}</span>
                          {assignment?.preferred && <SmileTwoTone style={{ fontSize: 14 }} />}
                        </div>
                      </div>
                    </Tooltip>
                  )
                }
              ),
              width: 140
            });
          })
        ]}
        scroll={{ y: elementRef.current?.clientHeight - 175 }}
        dataSource={R.range(1, numberOfRounds).map(roundNum => ({ name: `Round ${roundNum}`, round: roundNum }))}
        pagination={false}
        size="middle"
      />

      <HouseOwnerPicksEditor
        onClose={() => setCurrentOwnerEdit(null)}
        visible={!!currentOwnerEdit}
        houseOwner={currentOwnerEdit}
        houseOwners={houseOwners}
        year={bookingRound?.year}
        weeksRequired={bookingRound?.weeks_required}
        assignments={assignments}
      />
    </Container>
  )
}

export default BookingRoundDetails;
