import styled from 'styled-components';
import React, { useEffect, useState } from "react";
import { Descriptions, TabsProps } from 'antd';
import { Badge, Button, Card, Dropdown, Form, Menu, Modal, PageHeader, Space, Statistic, Table, Tabs } from "antd";
import { BrowserRouter, useNavigate, useParams } from "react-router-dom";
import { DownOutlined, EditOutlined, HomeOutlined, UserOutlined } from "@ant-design/icons";
import RecordTable from "../../components/RecordTable";
import {
  depositsBadgeStatuses,
  ExpenseConceptUnitSettingsModel,
  ILedgerCheckpoint,
  InvoiceModel,
  ITransactionModel,
  LedgerCheckpointModel,
  OwnerModel,
  TransactionModelModel
} from "../../types/models";
import ExpenseTransactionsEditor from "../../components/business/ExpenseTransactionsEditor";
import { ancanaApi } from "../../api/ancanaApi";
import moment from "moment";
import RecordSelect from "../../components/fields/RecordSelect";
import OwnerExpenses from "../../components/business/OwnerExpenses";
import useQuery from "../../hooks/useQuery";
import RecordCustomAction from "../../components/RecordCustomAction";
import { financeAmtFormat } from "../../utils";


const Container = styled.div`
  overflow-y: scroll;

  .ant-descriptions-item {
    padding-bottom: 0 !important;
  }

  .stat-card {
    width: 240px;
    text-align: center;
  }

  .ant-select-selector {
    cursor: pointer !important;
    margin-left: -12px !important;
    margin-right: 12px !important;
  }

  .editable-cell-value-wrap {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    :hover {
      padding-top: 1px;
      padding-bottom: 1px;
    }
  }

  .ant-tabs-tab  {
    letter-spacing: -0.5px;
    font-size: 13px;

    .anticon {
      margin-right: 8px;
    }
    .anticon-close {
      margin-right: 0;
    }
    .ant-tabs-tab-remove {
      margin-left: 4px;
    }
  }

  .sticky-bar {
    top: 0;
    position: sticky;
    z-index: 5;
    background-color: #f0f2f5;
    height: 32px;
  }

  .ant-descriptions-item-label {
    padding: 2px 12px !important;
  }

  .ant-descriptions-item-content {
    padding: 2px 12px !important;
    text-align: right;
    font-weight: 600;
  }

  .numbers-cont {
    text-align: right;
  }

  .ant-table-cell {
    padding: 4px 8px !important;
  }

  .ant-table-summary {
    background: rgb(251, 251, 250);
  }

  .sum-row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #e6e6e6;
  }

  .sum-cell {
    width: 160px;
    padding: 0 8px;
    margin: 4px 0;
    text-align: right;
  }

  .sum-head {
    background: rgb(251, 251, 250);
    font-weight: 500;

    .sum-cell {
      border-right: 1px #e6e6e6 solid;
      :last-child {
        border-right: none;
      }
    }
  }
`;

const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
  <DefaultTabBar {...props} className="sticky-bar sidento" />
);

export interface CheckpointAccountBalance {
  account_id: number;
  account_object_id: number;
  account_object_type: string;
  initial: { pending_balance: any; },
  current: { pending_balance: any; },
  account_name: string;
}

function MonthlyFinancials() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { query, handleFilter } = useQuery();
  const [checkpoint, setCheckpoint] = useState<ILedgerCheckpoint>();
  const [updatingCheckpoint, setUpdatingCheckpoint] = useState(false);
  const [contributions, setContributions] = useState<any>([]);
  const [reserveEntries, setReserveEntries] = useState<{ owner: string, owner_type: string, amount: number, direction: 'credit' | 'debit' }[]>([]);
  const [balances, setBalances] = useState<CheckpointAccountBalance[]>([]);
  const [currentTab, setCurrentTab] = useState<string>();
  const [form] = Form.useForm();
  const [transactionModels, setTransactionModels] = useState<ITransactionModel[]>([]);

  const changeStatus = async (status: 'open' | 'closed') => {
    if (!!checkpoint) {
      setUpdatingCheckpoint(true);
      await ancanaApi.patch(`ledger-checkpoints/${id}/`, { status });
      setCheckpoint({ ...checkpoint, status });
      setUpdatingCheckpoint(false);
    }
  }

  const onEdit = async (e: any, action: 'add' | 'remove') => {
    if (action === 'add') {
      Modal.confirm({
        title: `Add owner to this month's financials`,
        content: (
          <BrowserRouter>
            <Form
              className="pt-md"
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={async (data) => {
                const payload = { owners: [...(checkpoint?.owners.map(ow => ow.id) || []), Number(data.owner)] };
                await ancanaApi.patch(`ledger-checkpoints/${id}/?expand=ledger,owners.owner,owners.contribution_account`, payload);
                window.location.reload();
              }}
              onFinishFailed={() => {
              }}
              autoComplete="off"
            >
              <RecordSelect
                disabledKeys={checkpoint?.owners.map(ow => ow.id.toString())}
                extraFilterQuery={`&unit=${checkpoint?.ledger.object_id}`}
                recordModel={OwnerModel}
              />
            </Form>
          </BrowserRouter>
        ),
        okText: "Add",
        icon: null,
        onOk: (close: any) => form.submit()
      });
    }
    if (action === 'remove') {
      Modal.confirm({
        title: `Remove ${checkpoint?.owners.find(ow => ow.id.toString() === e.replace('owner', ''))?.owner.first_name} from this month?`,
        okText: "Remove",
        onOk: async () => {
          const payload = { owners: checkpoint?.owners.filter(ow => ow.id.toString() !== e.replace('owner', '')).map(ow => ow.id) };
          await ancanaApi.patch(`ledger-checkpoints/${id}/?expand=ledger,owners.owner,owners.contribution_account`, payload);
          window.location.reload();
        }
      });
    }
  };

  useEffect(() => {
    ancanaApi.get(
      `ledger-checkpoints/${id}` +
      '?expand=ledger,owners.owner,owners.contribution_account,owners.cash_account,owners.expenses_account,owners.unit.fractions' +
      '&fields=*,owners.*,owners.unit.fractions'
    ).then(res => {
      setCheckpoint(res.data);
    });
    ancanaApi.get(`ledger-checkpoints/${id}/owner-contributions`).then(res => {
      setContributions(res.data);
    });
    ancanaApi.get(`ledger-checkpoints/${id}/account-balances`).then(res => {
      setBalances(res.data);
    })
    ancanaApi.get(`ledger-checkpoints/${id}/reserve-entries`).then(res => {
      setReserveEntries(res.data);
    });
  }, [id]);

  useEffect(() => {
    const tabKey = query.get('tab');
    setCurrentTab(tabKey !== null ? tabKey : undefined);
    ancanaApi.get('transaction-models/?page_size=20'+TransactionModelModel.tableQuery).then(res => {
      setTransactionModels(res.data.results);
    });
  }, []);

  useEffect(() => {
    if (!!checkpoint) {
      document.title = `${moment(checkpoint?.year+'-'+checkpoint?.month+'-1').format('MMM YY')}, ${checkpoint?.ledger.name} | Financials`;
    }
  }, [checkpoint]);

  return (
    <Container className="anti-contento">
      <div className="contento" style={{ paddingBottom: 8 }}>
        <PageHeader
          onBack={() => navigate('/monthly-financials')}
          title={checkpoint?.ledger.name}
          subTitle={<>
            {!!checkpoint && (
              <RecordSelect
                defaultValue={checkpoint.id.toString()}
                extraFilterQuery={`&ledger=${checkpoint?.ledger.id}`}
                recordModel={LedgerCheckpointModel}
                bordered={false}
                labelMapper={r => {
                  const momentStart = moment().set('month', Number(r?.month) - 1).set('year', Number(r?.year)).startOf('month');
                  const momentEnd = moment().set('month', Number(r?.month) - 1).set('year', Number(r?.year)).endOf('month');
                  // return `From ${momentStart.format('MMM Do')}, to ${momentEnd.format('MMM Do YYYY')}`
                  return momentStart.format('MMMM YYYY')
                }}
                detailSwitcher={query.get('tab') ? '?tab=' + query.get('tab') : true}
              />
            )}
          </>}
          extra={checkpoint ? [
            // <Button key="2" icon={<DownloadOutlined />}>Export Statement</Button>,
            <RecordTable
              recordModel={InvoiceModel}
              recordsetFilters={{
                unit: checkpoint.ledger.object_id,
                billing_period: `${checkpoint.year}-${checkpoint.month.toString().padStart(2, '0')}-01`
              }}
              modalView
              allowRowSelect
            />,
            <RecordTable
              recordModel={ExpenseConceptUnitSettingsModel}
              recordsetFilters={{ unit: checkpoint.ledger.object_id }}
              modalView
            />,
            <Dropdown
              disabled={updatingCheckpoint}
              overlay={() => (
                <Menu>
                  {checkpoint?.status === 'open' ? (
                    <Menu.Item onClick={() => changeStatus('closed')}>Close</Menu.Item>
                  ) : (
                    <Menu.Item onClick={() => changeStatus('open')}>Open</Menu.Item>
                  )}
                </Menu>
              )}
              key="1"
              trigger={["click"]}
            >
              <Button className="capitalize" type="default" loading={updatingCheckpoint}>
                <Badge status={checkpoint?.status === 'closed' ? 'success' : 'processing'} /><Space>{checkpoint?.status}
                <DownOutlined /></Space>
              </Button>
            </Dropdown>,
          ] : []}
        >
        </PageHeader>
      </div>

      <Tabs
        renderTabBar={renderTabBar}
        defaultActiveKey="house"
        activeKey={currentTab}
        className="mt-sm white-bg mb-xl"
        type="editable-card"
        tabBarGutter={4}
        onEdit={onEdit}
        onTabClick={(key) => {
          handleFilter('tab', key, true);
          setCurrentTab(key);
        }}
      >
        <Tabs.TabPane
          closable={false}
          className="sidento pt-sm pb-xl"
          tab={<div><HomeOutlined />{checkpoint?.ledger.name}</div>} key="house">
          {!!checkpoint && (
            <div>
              <div className="disp-flex"
                   style={{ position: 'sticky', top: 6, backgroundColor: 'transparent', zIndex: 12 }}>
                <div className="right-text mr-sm disp-flex gap-sm sum-row" style={{ placeSelf: 'flex-end' }}>
                  <div className="fw-500">
                    Initial balance:
                  </div>
                  <div className="">
                    {financeAmtFormat(checkpoint?.starting_balance)}
                  </div>
                </div>
              </div>

              <div className="fs-3-25 fw-600 mb-sm">Deposits</div>
              <Table
                dataSource={contributions
                  .filter((c: any) => !!c.owner)
                  .sort((a: any, b: any) => a.owner.localeCompare(b.owner))
                  .map((c: any, idx: number) => {
                    const bal = contributions.slice(0, idx + 1).reduce((acc: number, c: any) => (c.status === 'posted' ? Number(c.amount) : 0) + acc, checkpoint.starting_balance);
                    return { ...c, balance: bal };
                  })}
                columns={[
                  { title: 'Trans', dataIndex: 'id', width: 70 },
                  { title: 'Owner', dataIndex: 'owner' },
                  { title: 'Description', render: r => r.name !== r.owner ? r.name : undefined },
                  {
                    title: 'Amount',
                    dataIndex: 'amount',
                    align: 'right',
                    render: a => financeAmtFormat(a)
                  },
                  {
                    title: 'Balance',
                    dataIndex: 'balance',
                    render: b => financeAmtFormat(b),
                    align: 'right',
                    width: 150
                  },
                  {
                    title: 'Status',
                    dataIndex: 'status',
                    align: 'right',
                    render: s => <Badge status={depositsBadgeStatuses[s]} text={s === 'posted' ? 'paid' : s} />,
                    width: 175,
                  },
                ]}
                size="small"
                sticky={{ offsetHeader: 32 }}
                pagination={false}
                summary={() => (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} />
                      <Table.Summary.Cell className="fw-600" index={1} colSpan={2}>
                        Total Deposits
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3} colSpan={1}>
                        <div className="numbers-cont fw-600" style={{ letterSpacing: -0.5 }}>
                          {financeAmtFormat(contributions.reduce((acc: number, c: any) => acc + Number(c.amount), 0))}
                        </div>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
              />
              <br /><br />

              <ExpenseTransactionsEditor
                disabled={checkpoint.status === 'closed'}
                account="Expenses"
                checkpoint={checkpoint}
                initialBalance={checkpoint.starting_balance + checkpoint.income_amount}
              />
              <br /><br />

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: 16 }}>
                <div className="sum-row sum-head">
                  <div className="sum-cell">
                    Property Expenses
                  </div>
                  <div className="sum-cell">
                    Owner Expenses
                  </div>
                  <div className="sum-cell">
                    Moved to Reserve
                  </div>
                  <div className="sum-cell">
                    Total Expenses
                  </div>
                </div>
                <div className="sum-row">
                  <div className="sum-cell">
                    {financeAmtFormat(checkpoint?.fractions_sold_expenses_amount)}
                  </div>
                  <div className="sum-cell">
                    {financeAmtFormat(checkpoint?.owner_expenses_amount)}
                  </div>
                  <div className="sum-cell">
                    {financeAmtFormat(checkpoint?.cash_reserved_amount)}
                  </div>
                  <div className="sum-cell">
                    {financeAmtFormat(checkpoint?.total_expenses_amount)}
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <div className="sum-row sum-head">
                  <div className="sum-cell">
                    Total deposits
                  </div>
                  <div className="sum-cell">
                    Total Expenses
                  </div>
                  <div className="sum-cell">
                    Period End Balance
                  </div>
                </div>
                <div className="sum-row">
                  <div className="sum-cell">
                    {financeAmtFormat(checkpoint?.income_amount)}
                  </div>
                  <div className="sum-cell">
                    {financeAmtFormat(checkpoint?.total_expenses_amount)}
                  </div>
                  <div className="sum-cell">
                    {financeAmtFormat(checkpoint?.ending_balance)}
                  </div>
                </div>
              </div>

              <br /><br />

              <div style={{ maxWidth: 850 }}>
                <div className="disp-flex h-stack mb-sm justify-between" style={{ alignItems: 'flex-end' }}>
                  <div className="fs-3-25 fw-600">Reserve Fund</div>
                  <div className="right-text disp-flex h-stack gap-0">
                    <div className="fw-500">Initial balance</div>
                    <div style={{ width: 154, textAlign: 'right', paddingRight: 8 }}>
                      {financeAmtFormat(checkpoint?.reserve_amount_till - (reserveEntries.reduce((a: number, e: any) => e.amount + a, 0)))}
                    </div>
                  </div>
                </div>
                <Table
                  pagination={false}
                  columns={[
                    { title: 'Trans', dataIndex: 't_id', width: 70 },
                    { title: 'Owner', dataIndex: 'owner' },
                    { title: 'Description', dataIndex: 'name' },
                    { title: 'Amount', dataIndex: 'amount', align: 'right' },
                    { title: 'Balance', dataIndex: 'balance', align: 'right', width: 150 },
                  ]}
                  dataSource={reserveEntries.map((e: any, idx: number) => {
                    const bal = reserveEntries.slice(0, idx + 1).reduce((acc: number, e: any) => e.amount + acc, 0);
                    return {
                      ...e,
                      amount: financeAmtFormat(e.amount),
                      balance: financeAmtFormat(bal)
                    };
                  })}
                />
                <div className="right-text mb-sm disp-flex h-stack gap-0 mt-sm" style={{ float: 'right' }}>
                  <div className="fw-500">Ending balance</div>
                  <div className="fw-600"
                       style={{ width: 154, textAlign: 'right', paddingRight: 8, letterSpacing: -0.4 }}>
                    {financeAmtFormat(checkpoint?.reserve_amount_till)}
                  </div>
                </div>
              </div>

              <div
                className="mt-md disp-flex h-stack gap-xs"
                style={{
                  background: 'transparent',
                  width: '100%',
                  justifyContent: 'flex-end',
                  position: 'sticky',
                  bottom: 4,
                  zIndex: 12
                }}
              >
                {!!checkpoint?.prev && <Button size="small" href={`/monthly-financials/${checkpoint?.prev.id}`}>← {moment(`${checkpoint?.prev.year}-${checkpoint?.prev.month}-1`).format('MMM YYYY')}</Button>}
                {!!checkpoint?.next && <Button size="small" href={`/monthly-financials/${checkpoint?.next?.id}`}>{moment(`${checkpoint?.next.year}-${checkpoint?.next.month}-1`).format('MMM YYYY')} →</Button>}
              </div>
            </div>
          )}
        </Tabs.TabPane>

        {checkpoint?.owners.map(owner => (
          <Tabs.TabPane
            className="sidento pt-sm pb-xl"
            tab={<div><UserOutlined />{owner.owner.first_name}</div>}
            key={`owner${owner.id}`}
          >
            {!!checkpoint && (
              <OwnerExpenses
                accountBalances={balances.filter(bal => bal.account_object_type === 'unitowner' && bal.account_object_id === owner.id)}
                unitOwnership={owner}
                checkpoint={checkpoint}
              />
            )}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Container>
  )
}

export default MonthlyFinancials;
