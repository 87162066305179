import React, { useEffect } from "react";
import { Form, Input, Tag, Button, Dropdown, Menu, Drawer, Space, FormInstance, Tooltip } from 'antd';
import { FileDoneOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { ancanaApi } from "../../api/ancanaApi";
import InputMessage from '../InputMessageAI';
import { ReactComponent as WandIcon } from '../../magic-wand.svg';
import { IContentType } from "../../types/models";

const Container = styled.div`
  .title-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .gap-5 {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  label {
    width: 100%;
  }

  position: relative;

  .input-footer{
    display: flex;
    width: 50%;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: -15px;
    font-size: 75%;
  }

  .generate-text {
    display: flex;
    align-items: center;
    gap: 16px;
    color: #9880ff;
  }

  .dot-flashing {
    position: relative;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .dot-flashing::before, .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -9px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 9px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: #9880ff;
    }
    50%, 100% {
      background-color: rgba(152, 128, 255, 0.2);
    }
  }
`;

interface AISuggestionsFieldProps {
  fieldName: string;
  modelName: string;
  instanceId?: number | string;
  recordToString: any;
  inputType?: 'input' | 'textArea';
  contentType?: IContentType;
  fieldSchema?: any;
}

interface FieldCompletionMessage {
  id: string;
  role: string;
  content: string;
  created_at: string;
}

function AISuggestions(props: AISuggestionsFieldProps) {
  const fieldProps = props;
  const [showLoading, setShowLoading] = React.useState(false);
  const [showOptions, setShowOptions] = React.useState(false);
  const [messagesList, setMessagesList] = React.useState<FieldCompletionMessage[]>();
  const [open, setOpen] = React.useState(false);
  const [newMessageForm] = Form.useForm();
  const fieldId = `field_chat_${props.modelName}_${props.instanceId}_${props.fieldName}`;
  const form = Form.useFormInstance();
  const allValues = Form.useWatch([], form);
  let instanceStr = '';
  try {
    instanceStr = props.recordToString(allValues);
  } catch (e) {}
  const isOneOff = !props.instanceId;
  const oneOffReady = !!instanceStr && instanceStr !== '';
  const enabled = !isOneOff || oneOffReady;

  const showDrawer = ()  => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getMessages(fieldId);
  }, []);

  async function generateText(field_id: string) {
    setShowLoading(true);
    if (props.instanceId) {
      const res: any = await ancanaApi.get(`field-completions/${field_id}`).catch(() => {});
      if (!res || res.status !== 200) {
        await ancanaApi.post('field-completions/', {
          "content_type": props.contentType?.id,
          "object_id": props.instanceId,
          "field": props.fieldName
        })
      }
      ancanaApi.get(`field-completions/${field_id}/generate`).then(response => {
        form.setFieldsValue({ [props.fieldName]: response.data.content });
        getMessages(fieldId);
        setShowLoading(false);
      }).catch(err => {
        setShowLoading(false);
        console.log(err)
      });
    } else {
      const payload = {
        "field_name": props.fieldName,
        "model_name": props.modelName,
        "instance_description": instanceStr
      }
      ancanaApi.post(`field-completions/one-off-generate/`, payload).then(response => {
        form.setFieldsValue({ [props.fieldName]: response.data });
        setShowLoading(false);
      });
    }
  }

  function getMessages(field_id: string): void {
    if (field_id) {
      ancanaApi.get(`field-completions/${field_id}/messages`).then(response => {
        setMessagesList(response.data);
      }).catch(err => {
        console.log('GET_MESSAGES', err)
      });
    }
  }

  function addNewMessage(data: any): void {
    if (fieldId) {
      ancanaApi.post(`field-completions/${fieldId}/messages/`, {
          role: 'user',
          content: data.new_message
        }
      ).then(response => {
        if (response.status === 200) {
          setMessagesList([...(messagesList ?? []), response.data]);
          newMessageForm.resetFields();
        }
      }).catch(err => {
        console.log('ADD_NEW_MESSAGE_ERROR', err);
      });
    }
  }

  const menu = (
    <Menu>
      <Menu.Item key={1} disabled>Translate</Menu.Item>
      <Menu.Item key={2} disabled>Key info</Menu.Item>
      <Menu.Item key={3} onClick={showDrawer}>Custom Autofill</Menu.Item>
    </Menu>
  );

  const GeneratingText = () => {
    return (
      <div className="generate-text">
        <div> Generating text </div>
        <div className="dot-flashing"></div>
      </div>
    )
  }

  return (
    <Container>
      <Form.Item
        name={fieldProps.fieldName}
        label={
          <div className="title-options">
            <div className="gap-5">
              {
                fieldProps.fieldName
                  .replace("__en", " in english").replace("__es", " in spanish")
                  .replaceAll("_", " ")
                  .replace(/^\w/, c => c.toUpperCase())
              }
              <Tag color="gold" >AI</Tag>
            </div>
            {showOptions &&
              <div className="gap-5">
                <Tooltip title={oneOffReady ? undefined : 'You must fill the other fields before being able to autofill'}>
                <Button
                  disabled={!enabled || showLoading} size="small" onClick={() => generateText(fieldId)}
                >
                  <div className="disp-flex h-stack gap-xxs center"><WandIcon style={{ width: 14 }} /> Autofill </div>
                </Button>
                </Tooltip>
                <Dropdown disabled={isOneOff} overlay={menu} placement="bottomRight">
                  <Button size="small">⋮</Button>
                </Dropdown>
              </div>
            }
          </div>
        }
      >
        {props.inputType === 'textArea' ? (
          <Input.TextArea
            showCount={!!props.fieldSchema?.max_length}
            maxLength={props.fieldSchema?.max_length}
            onFocus={() => setShowOptions(true)}
            autoSize={{ minRows: 3, maxRows: 10 }}
          />
        ) : (
          <Input/>
        )}
      </Form.Item>
      <div className="input-footer">
        {showLoading ? <GeneratingText /> : null}
      </div>
      <Drawer
        title={`Custom autofill for ${fieldProps.fieldName}`}
        placement="right"
        width={500}
        onClose={onClose}
        visible={open}
        footer={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
        {messagesList && messagesList.map((message: FieldCompletionMessage) => {
          return (
            <InputMessage id={message.id} role={message.role} content={message.content} created_at={message.created_at} fieldId={fieldId}/>
          )
        })}
        <Form
          form={newMessageForm}
          layout="vertical"
          name="basic"
          onFinish={addNewMessage}
          autoComplete="off"
          style={{ marginTop: "24px" }}
        >
          <Form.Item
           name="new_message">
            <Input.TextArea rows={7} />
          </Form.Item>
          <Form.Item>
            <Button block
              type="primary"
              icon={<PlusOutlined />}
              htmlType="submit">
              Add message
            </Button>
          </Form.Item>
      </Form>
      </Drawer>
    </Container>
  )
}

export default AISuggestions;
